/* WelcomeCard Container */
.welcome-card-container {
  padding: 0px;
}

/* Welcome Wrapper */
.welcome-wrapper {
  background: linear-gradient(90deg, #8A3FFC 0%, #3DD6DB 100%);
  border-radius: 24px;
  padding: 32px;
  max-width: 800px; 
  margin: 0 auto;  
}

/* Date Text */
.welcome-date {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 5px;
}
.frontd{
  color: #ffffff;
}

/* Greeting Text */
.welcome-greeting {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 8px;
}

.complete-profile-button{
  font-size: 12px;
}

@media (min-width: 768px) {
  .welcome-greeting {
    font-size: 35px;
  }
}

/* Message Text */
.welcome-message {
  color: #ffffff;
  width: 80%; 
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .welcome-message {
    font-size: 18px;
  }
}

/* Profile Completion Container */
.profile-completion {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

@media (min-width: 768px) {
  .profile-completion {
    align-items: flex-end;
  }
}

/* Complete Profile Button */
.complete-profile-button {
  font-size: 12px;
  color: #ffffff;
  display: none;
}

@media (min-width: 768px) {
  .complete-profile-button {
    display: block;
  }
}

/* Laptops (1280px and below) */
@media (max-width: 1400px) {
  .welcome-wrapper {
    width: 70%;
  }

  /* Greeting Text */
  .welcome-greeting {   
    font-size: 25px;
  }

  .welcome-message {
    font-size: 1rem;
  }
}

/* Mobile (600px and below) */
@media (max-width: 600px) {
  .welcome-wrapper {
    width: 100%;
    padding: 16px; 
    margin: 0; 
    border-radius: 10px; 
  }

  .welcome-message {
    width: 100%; 
  }
}
