@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  .topographyText{
    @apply font-Poppins not-italic tracking-normal
  }
}
/* Add this to your global CSS file (e.g., src/index.css) */
.phone-input-container .flag-dropdown {
  display: none !important; /* Use !important to override any inline styles */
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-text{
  background: var(--primary-gradient, linear-gradient(90deg, #8A3FFC 0%, #3DD6DB 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: Poppins;

font-style: normal;

line-height: normal;
}

