/* Section Container Styling */
.hiv-serve-links-section {
  max-width: 800px; 
  margin: 0 auto;
  padding: 20px; 
}

/* Title Styling */
.hiv-serve-links-section .title-Hivlinks {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center; 
}

/* Container for the service links */
.hiv-serve-links-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  justify-content: center; 
}

/* Individual Service Card */
.hiv-serve-card {
  background-color: #ffffff;
  border: 1px solid #dbdcff;
  border-radius: 8px;
  padding: 15px;
  text-align: left;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease, transform 0.3s ease;
  max-width: 180px;
  margin: 0 auto; 
}

.hiv-serve-card:hover {
  background-color: #f2efff;
  transform: translateY(-5px);
}

.hiv-serve-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #007bff;
}

.hiv-serve-card p {
  margin: 0;
  font-size: 16px;
  color: #787878;
  font-weight: 500;
  padding-top: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hiv-serve-links-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .hiv-serve-card {
    max-width: 150px;
  }

  .hiv-serve-card p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .hiv-serve-links-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .hiv-serve-card {
    max-width: 150px;
    padding: 10px;
  }

  .hiv-serve-card p {
    font-size: 12px;
  }

  .hiv-serve-icon {
    top: 8px;
    left: 8px;
  }
}
