/* styles.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  
  .modal-content {
    background: white;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    max-width: 25rem;
    width: 100%;
  }
  
  .modal-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .modal-text {
    margin-bottom: 1.5rem;
  }
  
  .modal-bold {
    font-weight: bold;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
  
  .modal-button {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .modal-button.no {
    background: #e0e0e0;
    color: #4a4a4a;
  }
  
  .modal-button.no:hover {
    background: #cfcfcf;
  }
  
  .modal-button.yes {
    background: #8A3FFC;
    color: white;
  }
  
  .modal-button.yes:hover {
    background: #781eff;
  }
  