/* Header Styles */
.header-background {
  background-color: #fff;
  box-shadow: 0 4px 6px #c3c3c31a;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  cursor: pointer;
}

.logo {
  height: 40px;
}

/* Profile Container */
.profile-container {
  position: relative;
  display: flex;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

/* Keeping profile name in its original position */
.profile-name {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  color: #8a3ffc;
}

/* Logout container for sign-out button below the profile */
.logout-container {
  position: absolute;
  top: 100%; /* Ensures the button appears directly below the profile image */
  left: 0;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 10;
  min-width: 120px;
}

/* Sign-out Button */
.sign-out-button {
  background: transparent;
  border: none;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 500;
  color: #8a3ffc;
  cursor: pointer;
  white-space: nowrap;
}

.sign-out-button:hover {
  color: #08b4bd;
}

/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 18%;
  height: 100vh;
  background-color: white;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
  overflow-y: auto;
}

.sidebar.large-screen {
  transform: translateX(0);
}

.sidebar.open {
  transform: translateX(0);
  width: 80%;
}

.sidebar-logo {
  width: 100px;
  height: auto;
  margin: 20px;
}

.sidebar-title,
.sidebar-subtitle {
  font-family: 'Poppins';
  font-weight: 500;
  line-height: 1.2;
  margin: 20px 0;
}

.sidebar-title {
  font-size: 18px;
}

.sidebar-subtitle {
  font-size: 20px;
}

.see-doctor-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  background-color: #17bdb9;
  color: #ffffff;
  border-radius: 40px;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  margin: 20px 0;
  padding: 6% 10%;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
}

.nav-item.hovered {
  background-color: #F2FFFF;
}

.nav-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.nav-text {
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 500;
  color: #737791;
}

.nav-item.hovered .nav-text {
  color: black;
}

.secure-info {
  background-color: #8a3ffc;
  border-radius: 10px;
  padding: 10%;
  position: relative;
  width: 80%;
  margin: 10px 10%;
}

.sidebar .secure-info .logout-btn {
  width: 90%;
  border-radius: 5px;
}

.secure-info-content {
  text-align: center;
}

.secure-info-title {
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 10px;
}

.logout-btn {
  background-color: #08b4bd;
  color: #ffffff;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 400;
  padding: 6px 12px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.middle_content {
  flex: 1;
  overflow-y: auto;
  padding: 0 20px;
  margin-bottom: 10px;
}

/* Hide scrollbar for Webkit browsers */
.middle_content::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

/* Hide scrollbar for IE, Edge and Firefox */
.middle_content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Mobile menu icon */
.menu-icon {
  display: none;
  position: fixed;
  top: 20px;
  left: 20px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10000;
}

.nav-icon {
  width: 15px;
  height: 15px;
}

.nav-text {
  font-size: 13px;
}

/* Overlay for mobile sidebar */
.overlays {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

/* Laptops (1400px and below) */
@media (max-width: 1400px) {
  .see-doctor-btn {
    width: 100%;
  }
}

/* Media queries for responsiveness */
@media (max-width: 1000px) {
  .sidebar {
    width: 80%;
    margin-left: 0;
    padding: 10px 40px;
    height: auto;
  }

  .sidebar-logo {
    width: 40%;
    margin: 20px 15px;
  }

  .sidebar-title,
  .sidebar-subtitle,
  .secure-info-title {
    font-size: 14px;
  }

  .see-doctor-btn {
    width: 100%;
    font-size: 12px;
  }

  .nav-icon {
    width: 16px;
    height: 16px;
  }

  .nav-text {
    font-size: 12px;
  }

  .secure-info {
    position: relative;
    width: 90%;
    padding: 5% 5%;
  }

  .menu-icon {
    display: block;
  }

  .middle_content {
    flex: 1;
    overflow-y: auto;
    padding-right: 20px;
    margin-bottom: 10px;
  }

  /* Bottom navigation styles */
  .bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999;
  }

  .bottom-nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #737791;
    font-family: 'Poppins';
    font-size: 12px;
  }

  .bottom-nav-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }

  .bottom-nav-text {
    font-size: 10px;
  }

  /* Style for Get HIV Care Now button with circular icon */
  .bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;  
    padding: 10px 0;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999;
  }
  
  .bottom-nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #737791;
    font-family: 'Poppins';
    font-size: 12px;
  }
  
  .bottom-nav-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .bottom-nav-text {
    font-size: 10px;
  }
  
  .bottom-nav-item.get-hiv-care .bottom-nav-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #17bdb9; 
  }
  
  .bottom-nav-item.get-hiv-care .bottom-nav-icon img {
    width: 24px;
    height: 24px;
  }
  
  .bottom-nav-item.active {
    color: black;
    font-weight: bold;
  }
}
