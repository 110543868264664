/* NotFound.css */

.notfound-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center;
    min-height: 100vh;
    background-color: #ffffff;
    padding: 20px;
    text-align: center;
  }
  
  .notfound-content {
    max-width: 500px;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .notfound-title {
    font-size: 1.75rem; 
    font-weight: bold;
    color: #2c2c2c;
    margin-bottom: 16px;
  }
  
  .notfound-message {
    font-size: 1rem;
    color: #606060;
    margin-bottom: 24px;
  }
  
  .notfound-buttons {
    display: flex;
    flex-direction: column;
    align-items: center; 
    gap: 10px;
  }
  
  .notfound-btn {
    background-color: #e7d7ff;
    color: #8A3FFC;
    padding: 10px 16px;
    border: 2px solid #8A3FFC;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    width: 80%; 
    max-width: 300px;
  }
  
  .notfound-btn:hover {
    background-color: #8A3FFC;
    color: #e7d7ff;
  }
  
  @media (min-width: 768px) {
    .notfound-buttons {
      flex-direction: row;
      justify-content: center; 
    }
  
    .notfound-title {
      font-size: 2rem; 
    }
  
    .notfound-message {
      font-size: 1.125rem;
    }
  }
  